<template>
  <view-container v-if="competition" name="details">
    <view-header>
      <custom-header class="view-header" :router-back="$goBack">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft" />
          {{ competition.name }}
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <div class="competitors">
        <h4>{{ $t('pages.details.organizer') }}</h4>

        <router-link class="organizer" to="/">
          <a-avatar :src="avatar" :size="32">
            <a-icon slot="icon" type="user" />
          </a-avatar>

          <div class="info">
            <div class="name">
              <span v-if="competition.organizer.name">
                {{ competition.organizer.name }}
              </span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="info">
        <a-divider></a-divider>

        <div class="details">
          <span>
            Competitors
            <span class="numbers ms-1">{{ competition.competitors }}</span>
          </span>
          <span class="value">
            <!-- Viewers <span class="numbers ms-1">{{ competition.viewers }}</span> -->
          </span>
        </div>

        <a-divider></a-divider>

        <template>
          <h4>{{ $t('pages.details.info.eventdate') }}</h4>

          <div class="date">
            <div class="event-date">
              <Icon :size="17" type="calendar" />

              <span> {{ startDate }} </span>
            </div>
            <span>-</span>
            <div class="event-date">
              <Icon :size="17" type="calendar" />

              <span> {{ endDate }} </span>
            </div>
          </div>

          <a-divider></a-divider>
        </template>

        <h4>{{ $t('pages.details.info.description') }}</h4>

        <p class="description">{{ competition.description }}</p>

        <a-divider></a-divider>
        <div v-for="event in competition.events" :key="event.id">
          <router-link class="tournament-link" :to="getEventLink(event)">
            <span class="tournament-link__name"> {{ event.name }} </span>
            <Icon :size="17" type="sword"
              :style="startListRelevated(event) && hasntVideo(event) ? { fill: '#D21341' } : ''"
              v-if="event.is_participant" />
            <div>
              <span v-if="allIsLive(event)" class=" tournament-link__status streamed">
                <Icon :size="14" type="check" style="fill: #51DF3A" class="me-1" />
                streamed
              </span>
              <span
                v-else-if="startListRelevated(event) && (meHasChallenge(event) || event.is_participant) && hasntVideo(event)"
                class="tournament-link__status compete">
                compete
              </span>
              <span
                v-else-if="registrOpen(event) && (!meHasChallenge(event) || (meHasChallenge(event) && !isAccepted(event)))"
                class="tournament-link__status join">{{
                  $t('pages.details.actions.join') }}</span>

              <span v-else class="tournament-link__status upcoming">
                upcoming
              </span>
              <Icon :size="26" class="tournament-link__icon" type="caret-right" />
            </div>
          </router-link>
        </div>
        <!-- <div v-for="event in participant" :key="event.id">
          <router-link class="tournament-link" :to="getEventLink(event)">
            <span class="tournament-link__name"> {{ event.name }} </span>
            <Icon :size="17" type="sword"
              :style="startListRelevated(event) && hasntVideo(event) ? { fill: '#D21341' } : ''"
              v-if="event.is_participant" />
            <div>
              <span v-if="allIsLive(event)" class=" tournament-link__status streamed">
                <Icon :size="14" type="check" style="fill: #51DF3A" class="me-1" />
                streamed
              </span>
              <span
                v-else-if="startListRelevated(event) && (meHasChallenge(event) || event.is_participant) && hasntVideo(event)"
                class="tournament-link__status compete">
                compete
              </span>
              <span
                v-else-if="registrOpen(event) && (!meHasChallenge(event) || (meHasChallenge(event) && !isAccepted(event)))"
                class="tournament-link__status join">{{
                  $t('pages.details.actions.join') }}</span>

              <span v-else class="tournament-link__status upcoming">
                upcoming
              </span>
              <Icon :size="26" class="tournament-link__icon" type="caret-right" />
            </div>
          </router-link>
        </div> -->
      </div>
    </view-content>
  </view-container>
</template>

<script>
// import competition from '@/mixins/competition'
import { getTournament } from '@/api/public/competition'
import Icon from '@/components/Icon'
import moment from 'moment-timezone'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Details-tournament',
  // mixins: [
  //   competition
  // ],
  props: {
    tournamentId: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    Icon
  },
  created() {
    this.initCompetition()
  },
  data() {
    return {
      competition: null,
    }
  },
  computed: {
    ...mapGetters({
      participant: 'participant'
    }),
    startDate() {
      const date = this.competition?.start_at
      if (!date) {
        return null
      }
      return moment(date).format('DD MMM YYYY')
    },
    endDate() {
      const date = this.competition?.end_at
      if (!date) {
        return null
      }
      return moment(date).format('DD MMM YYYY')
    },
    avatar() {
      return this.competitor?.photo || this.competition?.organizer?.photo || null
    },
  },
  methods: {
    ...mapActions({
      getParticipant: 'getParticipant'
    }),
    async initCompetition() {
      try {
        const { data: competition } = await getTournament(this.tournamentId)
        this.getParticipant(competition)
        this.competition = competition
        console.log(this.participant);
        console.log(this.competition);
      } catch (e) {
        console.log(e)
      }
    },
    allIsLive(event) {
      if (event.competitions.length === 0) {
        return false
      }
      for (let competition of event.competitions) {
        if (competition.status !== 'video-is-live') {
          return false
        }
      }
      return true
    },
    allAreCancelled(event) {
      for (let competition of event.competitions) {
        if (competition.status !== 'cancelled') {
          return false
        }
      }
      return true
    },
    registrOpen(event) {
      if (event.competitions.length === 0) {
        return true
      }
      for (let competition of event.competitions) {
        if (competition.status === 'registration-open') {
          return true
        }
      }
      return false
    },
    startListRelevated(event) {
      for (let competition of event.competitions) {
        if (competition.status === 'start-list-revealed') {
          return true
        }
      }
      return false
    },
    meHasChallenge(event) {
      if (event.me_has_challenges) {
        return true
      } else {
        return false
      }
      // for (let competition of event.competitions) {
      //   if (competition.status === 'start-list-revealed') {
      //     return true
      //   }
      // }
      // return false
    },
    isAccepted(event) {
      for (let competition of event.competitions) {
        if (competition.challenges.find(el => el.opponent.id === this.$store?.state?.auth?.user?.id && el.track_number !== null)) {
          return true
        }
      }
      return false
    },
    hasntVideo(event) {
      if (!event.me) {
        return true
      } else {
        if (event.me && event.me.has_video) {
          return false
        }
        return true
      }
    },
    getEventLink(event) {
      if (this.allIsLive(event) === true) {
        return { name: 'streamed-tournament-event', params: { id: event.id } }
      } else {
        return { name: 'details-tournament-event', params: { id: event.id } }
      }

    }
  }
}
</script>

<style scoped>
.organizer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin: 0 0 16px 0;
  color: #fff;
  border-radius: 8px;
}

.details {
  font-size: 12px;
  color: #7b8b97;
}

.details .numbers {
  color: #fff;
}

.date {
  gap: 10px;
}

.tournament-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.tournament-link__name {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
}

.tournament-link__icon {
  fill: #fff;
}

.tournament-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.streamed {
  color: #51DF3A;
  font-size: 10px;
}

.upcoming {
  color: #FFA843;
  font-size: 10px;
}

.join {
  color: #5BBE57;
  font-size: 10px;
}

.compete {
  color: #D21341;
  font-size: 10px;
}

.description {
  overflow-wrap: break-word;
}
</style>
